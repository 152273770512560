.home {

    &__hero {
        height: 90vh;
        overflow: hidden;
        position: relative;

        &__slide{
            height: 90vh;
            position: relative;

            img {
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                height: 100%;
                z-index: 0;
                min-width: 100%;

                @include mq(md){
                    width: 100%;
                    height: auto;
                    min-height: 100%;
                }
            }

            &__overlay {
                @include overlay(.3);
            }
        }

        &__data {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            z-index: 5;
            color:$white;
            text-align: center;
            left:0;
            right:0;
            margin: auto;
            max-width: 800px;
            width: 90%;

            @include mq(sm){
                width: 75%;
            }
        }

        &__title {
            @include title($align: 'center');

            @include mq(md){
                font-size: $head/1.5;
            }

            @include mq(lg){
                font-size: $head;
            }
        }

        &__desc {
            width: 80%;
            margin: 20px auto;
        }
    }

    &__marale {
        @include padd;

        background: $bgSecondary;
        color: $white;

        &__title {
            @include title($xxl);
            margin: 0 0 10px;
            @include mq(md) {
                margin: 0 0 20px;
            }
        }

        &__text {
            margin-bottom: 10px;
            @include mq(md) {
                margin-bottom: 20px;
            }
        }

        &__img,
        ._button {
            margin-bottom: 20px;
            @include mq(md) {
                margin-bottom: 40px;
            }
        }
    }

    &__places {

        display: flex;
        flex-direction: column;
        color: $white;

        @include mq(md) {
            height: 100vh;
            overflow: hidden;
            flex-direction: row;
        }

        &__overlay {
            @include overlay(.6, 0);
        }

        &__col {
            height: 42vh;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            position: relative;

            @include mq(sm){
                height: 45vh;
                flex-basis: 50%;
                width:50%;
            }

            @include mq(md){
                height: 100vh;
            }

            &--resto {
                background: url('/images/home/resto.jpg') no-repeat center center;
                background-size: cover;
            }

            &--hotel {
                background: url('/images/home/hotel.jpg') no-repeat right bottom;
                background-size: cover;
            }
        }

        &__data {
            position: relative;
            z-index: 2;
        }

        &__logo {
            height: 90px;
            margin-bottom: 10px;

            @include mq(sm){
                height: 170px;
                margin-bottom: 20px;
            }
        }

        &__desc {
            font-size: $xs;
            width: 90%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 10px;

            @include mq(sm){
                margin-bottom: 20px;
                width: 60%;
            }
        }
    }

    &__wine {
        @include mq(md) {
            height: 88vh;
            overflow: hidden;
        }

        &__bottles {
            display: flex;
            flex-direction: column;

            @include mq(md){
                flex-direction: row;
            }
        }

        &__bottle {
            $b: &;

            @include trans;

            height: 60vh;
            overflow: hidden;
            position: relative;

            @include mq(md){
                height: 88vh;
                flex-basis: 20%;
                width: 20%;
                flex: 1 1;

                &:hover {
                    flex-basis: 30%;
                    width: 30%;
                    flex: 1.5 1;

                    #{$b}__img{
                        width: 90%;
                    }

                    #{$b}__name,
                    #{$b}__outer ._button{
                        opacity: 1;
                    }

                }
            }

            &:nth-child(1){
                background: url('/images/home/pattern1.gif');
            }

            &:nth-child(2){
                background: url('/images/home/pattern2.gif');
            }

            &:nth-child(3){
                background: url('/images/home/pattern3.gif');
            }

            &:nth-child(4){
                background: url('/images/home/pattern4.gif');
            }

            &:nth-child(5){
                background: url('/images/home/pattern5.gif');
            }

            &__img {
                @include trans;
                position: absolute;
                right: 0;
                top:-70px;
                transform: translateX(33%);

                @include mq(sm){
                    transform: translate3d(0, -50%, 0);
                    right: 10%;
                    top: 50%;
                }

                @include mq(md){
                    right: 0;
                    left: 0;
                    margin: auto;
                    width: 100%;
                    max-width: 260px;
                }
            }

            &__name {
                @include trans;
                @include title(xxl);
                position: absolute;
                top:35%;
                left:40px;
                width: 50%;
                z-index: 10;
                text-shadow: 0 0 5px rgba(0,0,0,.3);

                @include mq(sm, only){
                    font-size: $head/1.5;
                }

                @include mq(md) {
                    text-align: center;
                    top:40px;
                    left: 0;
                    right: 0;
                    margin: auto;
                    width: 100%;
                    opacity: 0;
                }

                &--inner {
                    color:$black;
                    position: static;
                    text-align: center;
                    width: 100%;
                    @include mq(md){
                        margin-bottom: 20px;
                    }
                }
            }

            ._button {
                @include trans;
                position: absolute;
                bottom:40px;
                left:40px;

                @include mq(sm){
                    bottom:80px;
                }

                @include mq(md){
                    opacity: 0;
                    left:0;
                    right: 0;
                    margin: auto;
                    bottom: 40px;
                    max-width: 180px;
                }
            }

            &__inner {
                @include trans;

                opacity: 0;
                pointer-events: none;
                background: $white;
                position: absolute;
                z-index: 5;
                top:0;
                bottom:0;
                left:0;
                right:0;
                padding: 40px;
                color:$darkestGray;
                display: flex;
                align-items: center;

                @include mq(sm){
                    width: 50%;
                    left: initial;
                    transform: translateX(100%);
                }

                @include mq(md){
                }

                ._button.-white {
                    position: static;
                    border:1px solid $lightGray;

                    i {
                        margin-right: 10px;
                    }

                    @include mq(sm){
                        max-width: 220px;
                    }
                }
            }

            &__close {
                @include close($color:$black);

                top:20px;
                right:20px;
            }

            &__desc {
                margin-bottom: 40px;
            }

            &__list-name {
                @include title($color:$darkestGray);
            }

            &__list {
                margin-bottom: 40px;
                li {
                    display: flex;
                    justify-content: space-between;
                    font-size: $md;
                    font-weight: 700;
                    padding: 10px 0;
                    border-bottom: 1px solid $lightGray - #111;
                }
            }

            &.-open {
                height: 100vh;

                @include mq(sm){
                    height: 60vh;
                }

                @include mq(md){
                    height: 88vh;
                    flex-basis: 50%;
                    width: 50%;
                }

                #{$b}__inner {
                    opacity: 1;
                    pointer-events: all;

                    @include mq(sm){
                        transform: translateX(0);

                        ._button {
                            opacity: 1;
                        }
                    }
                }

                #{$b}__outer ._button {
                    opacity: 0;
                }

                #{$b}__name {
                    opacity: 0;

                    &--inner {
                        opacity: 1;
                    }
                }

                #{$b}__img {
                    transform: translate3d(-110%, -50%, 0);

                    @include mq(md){
                        transform: translate3d(-75%, -50%, 0);
                    }
                }
            }
        }
    }

    &__valle {
        color: $white;

        &__section {
            $s: &;

            position: relative;
            height: 100vh;

            &__col {
                height: 100vh;
            }

            &--one {
                background: url('/images/home/valle2.jpg') no-repeat center center fixed;
                background-size: cover;

                #{$s}__data {
                    position: absolute;
                    bottom: 20%;
                }
            }

            &--two {
                background: url('/images/home/valle1.jpg') no-repeat center center;
                background-size: cover;

                #{$s}__data {
                    position: absolute;
                    bottom: 20%;
                }
            }

            &--three {
                background: url('/images/home/valle3.jpg') no-repeat center center fixed;
                background-size: cover;

                #{$s}__data {
                    position: relative;
                    top: 10%;
                }
            }
        }
    }

    &__contact {
        background: $bg;
        color: $darkestGray;
        display: flex;
        flex-direction: column;

        @include mq(sm){
            flex-direction: row;
        }

        &__col {
            position: relative;
            flex-basis: 100%;
            width: 100%;
            display: flex;

            @include mq(sm){
                flex-basis: 50%;
                width: 50%;
            }

            &--form {
                justify-content: flex-end;
                padding: 8% 0;
            }

            &--img {
                overflow: hidden;
                @include mq(xs){
                    height: 50vh;
                }
            }
        }

        &__container{
            padding: 0 20px;

            @include mq(sm){
                width: (732px/2);
            }
            @include mq(md){
                width: (952px/2);

            }
            @include mq(lg){
                width: (1152px/2);
            }
        }

        &__title {
            @include title($size: $xxl, $color: $black);
            margin-bottom: 20px;
        }

        &__form,
        &__desc {
            margin-bottom: 20px;

            @include mq(md){
                width: 70%;
            }
        }

        &__group {
            border-bottom: 1px solid $lightGray - #111;
            padding: 7px 0;
            display: flex;
            align-items: baseline;
        }

        &__label{
            margin: 0;
            font-size:$xs;
            width: 70px;
        }

        &__input {
            border:none;
            width: calc(100% - 70px);
            padding: 3px;

            &:focus {
                @include trans;

                background: $lightestGray;
                outline: none;
                box-shadow: none;
            }
        }

        ._button {
            float: right;
            margin-top: 20px;
        }

        &__img {
            width:100%;
            height: auto;

            @include mq(sm){
                height: 100%;
                width: auto;
            }
        }
    }
}
