// Normalize Styles
@import 'node_modules/normalize.css/normalize';
@import 'bower_components/font-awesome/scss/font-awesome';
@import 'bootstrap';
@import 'https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i';
@import 'typography';
@import 'bower_components/slick-carousel/slick/slick.scss';
@import 'animate';

// Import Modules
@import 'mixins';
@import 'slick-theme';
@import 'modules';
@import 'pages';
@import 'components';

// Box sizing partial styles:
// Apply a natural box layout model to all elements
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
    font-family: $mainFont;
    font-weight: 400;
    overflow-x: hidden;
    width: 100vw;
    font-size: $sm;

    //temp
    background: $black;
}

main,
footer {
    @include trans(all, .5s);
}

main {
    padding-top: 50px;
    @include mq(md){
        padding-top: 80px;
    }
}

h1, h2, h3, h4, h5, h6 {
    font-family: $mainFont;
    font-weight: 700;
}

a {
    color:$link;
    transition: all $duration $easingLight;

    &:hover{
        color:$linkHover;
        text-decoration: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

// Styling on warning for users on IE7 or below
.browsehappy {
  text-align: center;
  margin: 0;
  background: #fff8e2;
  color: #000;
  padding: 0.5em 0;
}
