.hotel {
    &__hero {
        height: 90vh;
        overflow: hidden;
        position: relative;
        background: url('/images/hotel/hero.jpg') no-repeat center center fixed;
        background-size: cover;

        &__overlay {
            @include overlay;
        }

        &__data {
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            z-index: 5;
            color:$white;
            text-align: center;
            left:0;
            right:0;
            margin: auto;
            max-width: 800px;
            width: 90%;

            @include mq(sm){
                width: 75%;
            }
        }

        &__title {
            @include title($align: 'center');

            @include mq(md){
                font-size: $head/1.5;
            }

            @include mq(lg){
                font-size: $head;
            }
        }
    }

    &__viva {
        @include padd;

        @include mq(xs){
            padding-bottom: 0;
        }

        background: $bgSecondary;
        color: $white;

        &__title {
            @include title($xxl);
            margin: 0 0 10px;
            @include mq(sm) {
                margin: 0 0 20px;
            }
        }

        &__text {
            margin: 10px 0;
            @include mq(sm) {
                margin: 0 0 20px;
            }
        }

        &__img {
            width: 100%;
        }
    }

    &__showcase{

        &__slider {
            margin-bottom: 0!important;
        }

        &__slide{
            $s: &;

            height: 90vh;
            position: relative;

            &__data {
                position: absolute;
                top:50%;
                transform: translateY(-50%);
                z-index: 5;
                color:$white;
                text-align: center;
                left:0;
                right:0;
                margin: auto;
                max-width: 800px;
                width: 90%;

                @include mq(sm){
                    width: 300px;
                    left: 10%;
                    top:20%;
                    right: initial;
                    text-align: left;
                }
            }

            &:nth-of-type(3){
                #{$s}__data {
                    @include mq(sm){
                        left: initial;
                        right: 15%;
                    }
                }
            }
        }
    }

    &__experience {
        &__top {
            background: $bg;
            padding: 20px 0;

            @include mq(sm){
                padding: 40px 0;
            }
        }

        &__bottom{
            height: 70vh;
            background: url('/images/hotel/experiencia.jpg') no-repeat center center fixed;
            background-size: cover;
        }

        &__title {
            @include title($xxl, $color: $lila);
            margin: 0 0 10px;
            @include mq(md) {
                margin: 0 0 20px;
            }
        }
    }

    &__features {
        $f: &;

        @include padd;

        background: $bgSecondary;
        color: $white;
        text-align: center;

        &__list {
            display: flex;
            flex-wrap: wrap;

            &--extra {
                #{$f}__feature {
                    @include mq(sm){
                        flex-basis: 16.6666%;
                        width: 16.6666%;
                    }
                }
            }
        }

        &__feature {
            flex-basis: 50%;
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;

            @include mq(sm){
                flex-basis: 25%;
                width: 25%;
                margin-bottom: 40px;
            }

            &__icon {
                margin-bottom: 10px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-height: 80px;
                }
            }

            &__text {
                font-size: $xxs;
            }
        }

        &__title,
        &__subtitle {
            margin-bottom: 20px;

            @include mq(sm){
                margin-bottom: 40px;
            }
        }

    }

    &__contact {
        $c: &;

        background: $bg;
        color: $darkestGray;
        display: flex;
        flex-direction: column;

        @include mq(sm){
            flex-direction: row;
        }

        &__col {
            position: relative;
            flex-basis: 100%;
            width: 100%;
            display: flex;

            @include mq(sm){
                flex-basis: 50%;
                width: 50%;
            }

            &--form {
                justify-content: flex-end;
                padding: 8% 0;
                background: $lightestGray;
            }

            &--img {
                overflow: hidden;
                @include mq(xs){
                    height: 50vh;
                }
            }
        }

        &__container{
            padding: 0 20px;

            @include mq(sm){
                width: (732px/2);
            }
            @include mq(md){
                width: (952px/2);

            }
            @include mq(lg){
                width: (1152px/2);
            }
        }

        &__title {
            @include title($size: $xxl, $color: $black);
            margin-bottom: 20px;

            @include mq(md){
                width: 70%;
            }
        }

        &__form,
        &__desc {
            margin-bottom: 20px;

            @include mq(md){
                width: 70%;
            }
        }

        &__group {
            border-bottom: 1px solid $lightGray - #111;
            padding: 7px 0;
            display: flex;
            align-items: baseline;

            &--half {
                @include mq(sm){
                    display: inline-block;
                    width: calc(50% - 10px);
                    margin-right: 7px;

                    #{$c}__input {
                        width: 100%;
                    }
                }
            }
        }

        &__label{
            margin: 0;
            font-size:$xs;
            width: 70px;
        }

        &__input {
            border:none;
            width: calc(100% - 70px);
            padding: 3px;
            background: $lightestGray;

            &:focus {
                @include trans;

                background: $white;
                outline: none;
                box-shadow: none;
            }
        }

        &__img {
            width:100%;
            height: auto;

            @include mq(sm){
                height: 100%;
                width: auto;
            }
        }

        ._button {
            float: right;
            margin-top: 20px;
        }
    }
}
