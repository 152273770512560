.header {

    $h: &;

    position: fixed;
    top:0;
    left:0;
    right:0;
    background: $white;
    z-index: 1000;

    &__container {
        position: relative;
        height: 50px;

        @include mq(md) {
            height: 80px;
        }
    }

    &__logo {
        position: absolute;
        height: 30px;
        top: 8px;

        @include mq(md) {
            height: 50px;
            top: 15px;
        }

        img {
            height: 30px;
            width: auto;

            @include mq(md) {
                height: 50px;
            }
        }
    }

    &__hamburguer {
        position: absolute;
        right:15px;
        top:15px;
        width: 86px;
        @include trans;

        @include mq(md) {
            top:30px;
            right:0;
        }

        a {
            color:$black;
            text-transform: uppercase;
            font-size: $xs;
            display: block;
            padding-top: 3px;
            font-weight: 700;
            @include trans;
        }

        span {
            display: block;
            position: absolute;
            right:0;
            height: 2px;
            background: $black;
            width: 26px;
            @include trans;

            &:nth-of-type(1){
                top: 3px;
            }

            &:nth-of-type(2){
                top: 10px;
            }

            &:nth-of-type(3){
                top: 17px;
            }
        }

        &:hover {
            a {
                color:$lila;
            }

            span {
                background: $lila;
            }
        }
    }

    &__nav {
        @include trans;

        position: fixed;
        top:0;
        bottom:0;
        right:0;
        width:100%;
        max-width: 390px;
        background: transparentize($black, .15);
        transform: translateX(100%);
        padding: 0 40px;
        display: flex;
        justify-content: center;
        flex-direction: column;

        @include mq(md){
            padding: 0 50px;
        }

        &__close {
            @include close;
            left:40px;
            top:20px;

            @include mq(md){
                left:50px;
                top:30px;
            }
        }
    }

    &__menu {
        margin-bottom: 40px;

        @include mq(md){
            margin-bottom: 50px;
        }

        a{
            color:$white;
            font-weight: 700;
            text-transform: uppercase;
            font-size: $md;
            margin-bottom: 5px;
            display: inline-block;

            @include mq(md){
                font-size: $lg;
            }
        }
    }

    &__social {
        color:$white;

        &__tagline {
            display: block;
            margin-bottom: 15px;
        }

        &__list {
            display: flex;

            li{
                margin-right: 20px;
            }

            a{
                font-size: $lg;
                color:$white;
                padding: 5px;
            }
        }
    }

    &.-open {

        #{$h}__nav{
            transform: translateX(0);
        }

        #{$h}__hamburguer{
            right:50px;
        }
    }
}
